.dashboard-setup-container {
  top: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 40;
  background-color: white;
  position: relative;
  /* padding: 0 16px; */
  font-weight: 400;
  overflow: hidden;
}

.acount-setup--actions-handler-wrapper .error-handler-container {
  top: 80px !important;
}

.dashboard-setup-container--inner {
  max-width: 1200px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

/* Dashboard Setup Onboarding Section */
section.dashboard-setup-onboarding-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 20px;
  margin-left: 18px;
  margin-right: 18px;
}

/* Section Header */

.dashboard-setup-top--left-wrapper {
  display: flex;
}

.digital_nomad_img_wrapper img {
  height: 250px;
}

.dashboard-setup-onboarding-section-header {
  width: 300px;
}

.dashboard-setup-container-onboarding-section-title {
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* Emoji Image */
.dashboard-setup-container-onboarding-section-title img {
  transform: translateY(5px);
}

/* Container Main Section */
.dashboard-setup-onboarding-section-main-container {
  width: 100%;
  margin-top: 40px;
}

/* Go to setup links wrapper container */
.go-to-setup-links-wrapper-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.go-to-setup-link-wrapper {
  max-width: 600px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

.go-to-setup-link-wrapper button {
  width: 100%;
  height: 90px;
  border-radius: 20px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 30px;
  cursor: pointer;

  /* Text Styles */
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #2e2e2e;
}

/* Star image Wrapper */
.star-image-wrapper {
  /* background-color: red; */
  position: absolute;
  bottom: -8px;
  left: -2px;
}

.go-to-company-info-setup-wrapper button {
  background-color: #f8eaf6;
}
/* 
.go-to-instrument-setup-wrapper button {
  background-color: #fbf9ff;
}

.go-to-onboard-employees-setup-wrapper button {
  background-color: #ffe9e9;
}

.go-to-subscription-setup-wrapper button {
  background-color: #f0e9fe;
} */

/*  SETUP LAYOUT SECTION */
/* Dashboard Setup layout Section */
.dashboard-setup-layout-container {
  position: relative;
  max-width: 1000px;
  margin: auto;
  padding: 50px 0;
  /* background-color: rebeccapurple; */
  /* padding-left: 50px; */
  /* overflow: hidden; */
}

.dashboard-setup-top-container {
  display: flex;
  align-items: center;
  margin-left: 25px;
  justify-content: space-between;
}

.push-wrapper-down {
  margin-top: 40px !important;
}

.dashboard-setup-top-container .back-button-wrapper button {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.settings--back-button-wrapper button {
  background-color: #f8f8fa !important;
}

.dashboard-setup-top-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #1a0404;
  margin-left: 25px;
}

/* Setup Layout main Container */
.dashboard-setup-layout-main-container {
  display: flex;
  margin-top: 40px;
}

/* Dashboard Setup LEFT Section */
.dashboard-setup-layout--left-section {
  width: 400px;
  margin-top: 60px;
  position: relative;
  /* background-color: red; */
}

.log-out-btn-wrapper {
  /* position: absolute;
  bottom: 0;
  left: 0; */
  margin-top: 30px;
}

.log-out-btn-wrapper button {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ce2e2e;
  color: #ffffff;
}

.form-navigation-link button {
  width: 374px;
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  /* Text styles */
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  color: #a9a9bc;
  background-color: #ffffff;
}

.form-navigation-link button img {
  margin-right: 15px;
}

/* Dashboard Setup RIGHT Section */
.dashboard-setup-layout--right-section {
  margin-left: 45px;
}

/* Profile photo update form group/ */

.account-details-setup-profile-photo-form-group {
  display: flex;
  align-items: center;
}

.profile-photo-display-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}

.profile-photo-display-wrapper img {
  top: -25%;
  left: -25%;
  position: absolute;
  object-fit: contain;
  width: 150%;
  height: 150%;
}

.upload-profile-photo-input-wrapper {
  width: 159px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  background: #ede9f7;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #0d0520;
}

.upload-profile-photo-input-wrapper input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
}

.active-form-nav-link {
  background-color: #f8f8fa !important;
  color: #2e2e2e !important;
}

/* All formgroups */
.account-details-setup-profile-photo-form-group,
.account-details-setup--inner form .form-group,
.instruments-setup--inner form .form-group,
.groups-setup--inner form .form-group,
.flat-salary-loan-rate-setup--inner form .form-group,
.benefits-setup--inner form .form-group,
.api-config-setup--inner form .form-group {
  margin-bottom: 25px;
}

/* All inputs */
.account-details-setup--inner form .form-group input,
.account-details-setup--inner form .form-group select,
.account-details-setup--inner form .form-group textarea,
.account-details-setup--inner form .file-form-group--inner {
  width: 500px;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */

.employer-phone-number-one-text-input {
  padding-left: 55px;
}

.submit-setup-form-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 25px;
}

.submit-setup-form-button-wrapper button {
  width: 250px;
  height: 50px;
  background-color: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  /* Text styles */
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 10px;
}

/* Instrument Ranges setup container */
.api-config-setup--inner .form-header-wrapper {
  margin-bottom: 25px;
}

/*  */
/*  */
/*  */
.none {
  display: none;
}
