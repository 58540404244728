body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

@font-face {
  /* 300 */
  font-weight: 300;
  font-family: "Moderat";
  src: url(./styles/fonts/Moderat-Thin.ttf);
}

/* 400 */
@font-face {
  font-weight: 400;
  font-family: "Moderat";
  src: url(./styles/fonts/Moderat-Regular.ttf);
}

@font-face {
  /* 500 */
  font-weight: 500;
  font-family: "Moderat";
  src: url(./styles/fonts/Moderat-Medium.ttf);
}

@font-face {
  /* 600 */
  font-weight: 600;
  font-family: "Moderat";
  src: url(./styles/fonts/Moderat-Bold.ttf);
}

@font-face {
  /* 700 */
  font-weight: 700;
  font-family: "Moderat";
  src: url(./styles/fonts/Moderat-Black.ttf);
}

/* Moderat-- font override */
.app,
html {
  background-color: #f8f8fa;
}

/* html {
  min-height: 100%;
} */

.app,
textarea {
  font-family: "Moderat";
}

/* .company--main-section-wrapper {
  position: relative;
  min-height: 100vh;
  margin-left: 260px;
  padding: 0 24px;
  margin-top: 120px;
  background-color: #f8f8fa;
} */

input::placeholder {
  font-size: 15px;
  line-height: 25px;
  font-family: "Moderat";
  font-weight: 400;
  color: #222222;
}

input:focus,
textarea:focus,
select:focus {
  outline: 1px solid #3a0ca3;
}

input[type="password"] {
  font-weight: 600;
}

input[type="radio"] {
  height: 24px !important;
  outline: none;
  accent-color: #3a0ca3;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

button {
  font-family: "Moderat";
  cursor: pointer;
}
/* Remove the default angle icon in select dropdown */

input,
textarea,
select {
  background-color: inherit !important;
}

select {
  /* For Firefox */
  -webkit-appearance: none;
  /* for Chrome */
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

textarea {
  resize: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

/*  */
.form-group {
  position: relative;
}
/*  */
/*  */
.none {
  display: none;
}

.below-table-loader-wrapper {
  margin-top: 20px;
  margin-bottom: 4px;
}

.payment-loader-wrapper {
  margin-right: 100px;
}

/* Primary Active Filter Option */
.active-filter-option {
  color: #1a0404 !important;
}

.active-filter-option::after {
  content: "";
  position: absolute;
  width: 180px;
  height: 4px;
  left: 0;
  bottom: 0;
  /* Gradient */
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
}

/* Secondary Table Filter Option Container*/
.secondary--table-filter-options-container {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 48px;
}

.secondary--table-filter-options-container::after {
  content: "";
  position: absolute;
  width: 95%;
  height: 2px;
  left: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: #d2c1fb;
}

.secondary--table-filter-option {
  position: relative;
  padding: 8px 5px;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px;
  color: #444444;
}

/* Secondary Active Filter Option */
.secondary--active-filter-option {
  color: #3a0ca3 !important;
}

.secondary--active-filter-option::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: #3a0ca3;
  z-index: 4;
}

/* Modals and Modal Overlay */
.overflow-hidden {
  overflow: hidden;
  height: 100vh;
}

.gray-overlay {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 50;
  transition: all ease-in-out 0.5s;
}

/* Primaary back button Section */
.primary-back-button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
}

.main--actions-handler-wrapper .error-handler-container,
.app--actions-handler-wrapper .error-handler-container,
.app--actions-handler-wrapper .success-handler-container {
  top: 80px;
}

.upload-loading-spinner-wrapper {
  width: 15px;
  height: 15px;
  border: 4px solid #cccccc; /* Light grey */
  border-top: 4px solid #3a0ca3; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  z-index: 40;
  margin: 0 4px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
