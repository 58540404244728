.api_configuration_section {
  margin-top: 25px;
}

.api_configuration_title {
  font-size: 24px;
  font-weight: 600;
}

.empty_key_wrapper {
  margin-top: 35px;
  font-size: 18px;
}

.api_secret_key_form_group {
  position: relative;
  margin-top: 30px;
  max-width: 450px;
}

.api_secret_key_form_group input {
  width: 100%;
}

.secret-key-copy-btn-wrapper {
  position: absolute;
  right: -40px;
  top: 30px;
}

.secret-key-copy-btn-wrapper button {
  border: none;
  background-color: inherit;
  padding: 0;
}

.generate-secret-key-btn-container {
  margin-top: 60px;
}

.generate-secret-key-btn-wrapper button {
  width: 240px;
}
