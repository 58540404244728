.primary-back-button-container {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

/* Primary Back Button */
.primary-back-button-wrapper button {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
}

.primary-back-button-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 19px;
  color: #2e2e2e;
}

.primary-back-button-title span {
  font-size: 16px;
  font-weight: 400;
  color: #707070;
}
