.dashboard-message-wrapper .error-handler-container {
  top: 135px !important;
}

.merchant-dashboard-container-wrapper {
  padding-bottom: 60px;
}

/* Merchant dashboard ROW ONE SECTION */
section.merchant-container-row--one {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px 30px;
}

.row-one--left-container {
  max-width: 500px;
  width: 100%;
  height: 150px;
  border: 1px solid #ede9f7;
  border-radius: 20px;
  padding: 15px 25px 0px;
}

.row-one--middle-container {
  margin: 0 10px;
}

/* Row one Left, Middddle and Right Container */
.row-one--left--top-wrapper,
.row-one--left--bottom-wrapper,
.row-one--middle--top-wrapper,
.row-one--middle--bottom-wrapper,
.row-one--right--top-wrapper,
.row-one--right--bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-one--box-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  color: #0d0520;
}

.make-withdrawal-btn-container button {
  width: 120px;
  height: 43.33px;
  background: #3a0ca3;
  border-radius: 9px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 12px;
  color: #ffffff;
  transition: all ease-in-out 0.2s;
}

.make-withdrawal-btn-container button:hover {
  background: #320a90;
}

.sales-level-sort-dropdown-wrapper,
.stock-level-sort-dropdown-wrapper {
  position: relative;
}

.stock-level-sort-dropdown-wrapper .select-dropdown-wrapper,
.sales-level-sort-dropdown-wrapper .select-dropdown-wrapper {
  background-color: #f8f8fa;
}

.sales-level-sort-dropdown-wrapper select,
.stock-level-sort-dropdown-wrapper select {
  width: 110px;
  height: 33.33px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 13px;
  line-height: 12px;
  color: #dadada;
  position: relative;
  z-index: 4;
}

.sales-level-sort-dropdown-wrapper select option,
.stock-level-sort-dropdown-wrapper select option {
  font-size: 14px;
  color: #0d0520;
}

.sales-level-sort-dropdown-wrapper .dropdown-icon img,
.stock-level-sort-dropdown-wrapper .dropdown-icon img {
  width: 19.85px;
  height: 17.17px;
  position: absolute;
  height: 24px;
  top: 6px;
  right: 5px;
  filter: invert(1);
  z-index: 1;
}

/* row-one--left, middle and right --bottom-wrapper */
.row-one--left--bottom-wrapper,
.row-one--middle--bottom-wrapper,
.row-one--right--bottom-wrapper {
  margin-top: 30px;
}

/* wallet-balance-value-wrapper && sales-level-percentage-wrapper */
.wallet-balance-value-wrapper,
.sales-level-percentage-wrapper,
.stock-level-percentage-wrapper {
  font-weight: 500;
  font-size: 36px;
  line-height: 38px;
  color: #3a0ca3;
}

.row--one-percent-change-wrapper {
  width: 75px;
  height: 39px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  /* identical to box height, or 117% */
}

.percent-increase-wrapper {
  background-color: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

.percent-decrease-wrapper {
  background-color: rgba(226, 49, 49, 0.1);
  color: #e23131;
}

.row--one-percent-change-wrapper img {
  transform: translateY(1px);
  padding-left: 5px;
}

/* Media Queries */
@media (min-width: 1400px) {
  .merchant-dashboard-container--inner {
    max-width: 1460px;
    margin: auto;
    padding: 0 30px;
  }

  .row-one--left-container {
    height: 150px;
  }

  .row-two-main-container,
  .row-three-main-container {
    height: 380px;
    border: 1px solid #ede9f7;
    border-radius: 20px;
  }
}

@media (max-width: 900px) {
  .row-one--left-container {
    max-width: 100%;
  }
}
