/* FundWalletModal Modal */
.fund-wallet-modal-container {
  position: absolute;
  width: 395px;
  min-height: 200px;
  z-index: 50;
  left: 40%;
  top: 25%;
  background: #ffffff;
  border-radius: 20px;
}

.fund-wallet-modal-container {
  padding: 25px 35px;
}

.fund-wallet-modal-header {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  width: 100%;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  color: #3a0ca3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reason-for-declination-form-wrapper form {
  width: 100%;
  margin-top: 30px;
}

.reason-for-declination-form-wrapper form input {
  width: 100%;
}

.reason-for-declination-form-wrapper form .form-group {
  width: 100%;
}

.reason-for-declination-form-wrapper .form-group label {
  line-height: 22px;
}

.reason-for-declination-form-wrapper .form-group label span {
  padding: 0;
  color: #3a0ca3;
  font-size: 14px;
  font-weight: 400;
}

/* reason-for-declination form group */
.reason-for-declination-form-group textarea {
  margin-top: 6px;
  width: 100%;
  height: 100px !important;
  resize: none;
  padding: 5px 15px;
  border: 1px solid #c0bace;
  box-sizing: border-box;
  border-radius: 10px;
  /* text styles  */
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  color: #1a0404;
}

.fund-wallet-modal-action-buttons-wrapper {
  margin-top: 45px;
}

.submit-fund-wallet-btn-wrapper {
  display: flex;
  align-items: center;
}

.submit-fund-wallet-btn-wrapper button {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  background-color: #3a0ca3;
  color: #ffffff;
}
