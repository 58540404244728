.successful-form-submission-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #f8f8fa;
}

.successful-form-submission-container--inner {
  padding-top: 80px;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Main Header text wrapper  */
.successful-form-submission-container--inner .container-main-header-text {
  font-weight: 600;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  margin-top: 10px;
  /* Gradient */
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
/* Sub Header text wrapper  */
.successful-form-submission-container--inner .container-sub-header-text {
  margin-top: 10px;
  max-width: 400px;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: #4d4d4d;
}

.none {
  display: none;
}

.res-succ-login-button-wrapper {
  margin-top: 30px;
}

.res-succ-login-button-wrapper button {
  width: 210px;
}
