.teams_table_action_btn_wrapper {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  column-gap: 30px;
}

.teams_table_action_btn_wrapper button {
  width: 200px;
}
