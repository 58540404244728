.confirm_action_modal_container {
  position: absolute;
  width: 318px;
  min-height: 150px;
  z-index: 50;
  /* transform: translate(50%); */
  left: 40%;
  top: 25%;
  background: #ffffff;
  border-radius: 20px;
}

.confirm_action_modal_container__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 35px;
}

.confirm_action_header_text {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #1a0404;
  margin-top: 20px;
}

.confirm_action_sub_text {
  width: 213px;
  font-size: 12.5px;
  line-height: 15px;
  text-align: center;
  color: #a9a9bc;
  margin-top: 20px;
}

.confirm_action_sub_text span {
  color: #3a0ca3;
}

.modal_action_button_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.confirm_modal_action_button {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.cancel_modal_button_wrapper button,
.confirm_modal_action_button button {
  border: none;
  outline: none;
  cursor: pointer;
}

.cancel_modal_button_wrapper button {
  background-color: inherit;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #ff4848;
}

.confirm_modal_action_button button {
  width: 169px;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
  transition: all ease-in-out 0.2s;
}

.confirm_modal_action_button button:hover {
  background: #320a90;
}
