.signin-container {
}

.signin-container--inner {
  max-width: 2000px;
  margin: auto;
  padding-top: 80px;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  position: relative;
  overflow: hidden;
}

/* Signin Left section  / Auth Left Section*/
.auth-left-section {
  grid-column: span 5 / span 5;
  background-color: #fbf9ff;
  overflow: hidden;
  position: relative;
}
/* Signin Right section  / Auth Right Section*/
.auth-right-section {
  grid-column: span 7 / span 7;
  display: flex;
  justify-content: center;
}

/* Signin Left section */
/* signin-left-section-text-wrapper */
.signin-left-section-text-wrapper {
  width: 364px;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 15%;
  transform: translateX(20%);

  /* font-weight: 600; */
}

.left-auth-section-image-wrapper {
  position: absolute;
  bottom: -5px;
  left: 0;
}

.left-auth-section-image-wrapper img {
  max-width: 517px;
}

/* Signin Right section */
.signin-right-section--inner {
  margin-top: 11%;
  max-width: 330px;
}

.signin-right-section-header-title {
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  color: #1a0404;
}

/* Signin main form section */
.signin-main-form-section {
  margin-top: 40px;
}

.signin-email-form-group input,
.signin-password-form-group input {
  width: 300px;
}

.signin-password-form-group {
  position: relative;
  /* margin-top: 25px; */
  margin-top: 25px;
}

.password-icon-wrapper {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
}

.forgot-password-link-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}

.forgot-password-link-wrapper a {
  text-decoration: none;
  color: #3a0ca3;
  font-weight: 500;
}

/* Submit form button wrapper */
.log-in-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.log-in-button-wrapper button {
  width: 250px;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  /* Text styles */
  font-weight: "moderat-medium";
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 10px;
}

/* Don't have an account wrapper  */
.dont-have-an-account-wrapper {
  font-size: 15px;
  line-height: 24px;
  color: #2e2e2e;
  margin-top: 20px;
}

/* Row one */
.dont-have-an-account-wrapper .row-one {
  text-align: center;
}

.dont-have-an-account-wrapper span a {
  font-size: 16px;
  text-decoration: none;
  padding: 0 5px;
  color: #3a0ca3;
  font-weight: 600;
}

/* visibiliity hidden */
.hidden {
  visibility: hidden;
}

/* Media Queries */
@media (max-width: 1080px) {
  .signin-left-section-text-wrapper {
    transform: translateX(5%);
  }
}

@media (max-width: 990px) {
  .signin-left-section-text-wrapper {
    width: 320px;
    font-size: 36px;
  }

  .signin-right-section-header-title {
    font-size: 36px;
  }
}
