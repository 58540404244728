.awaiting-verification-container {
  position: relative;
  overflow: hidden;
  background-color: #f8f8fa;
  height: 100%;
}

.awaiting-verification-wrapper {
  max-width: 1200px;
  /* min-height: 90vh; */
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.resend-verification-link--button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.resend-verification-link--button-wrapper button {
  background-color: #3a0ca3;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;
}

.verification-loader-wrapper {
  margin-left: 10px;
}

/* Media Queries */
@media (max-width: 520px) {
  .awaiting-verification-wrapper {
    margin-top: 130px;
  }
}
