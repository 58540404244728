.app-top-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 45;
  background-color: white;
  padding: 0 16px;
  font-weight: 400;
}

.app-top-container--inner {
  max-width: 1200px;
  height: 80px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Search Bar Container */
.top-search-bar-container {
  position: relative;
}

.top-search-bar-container input {
  width: 650px;
  height: 50px;
  left: 427px;
  top: 32px;
  background-color: #f8f8fa !important;
  border-radius: 5px;
  border: none;
  outline: none;
  padding-left: 60px;
  padding-right: 10px;
  /* Text Styles */
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #8e8e8e;
}

.top-search-bar-container input::placeholder {
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #8e8e8e;
}

.search-icon-wrapper {
  position: absolute;
  top: 13px;
  left: 20px;
}

.search-icon-wrapper img {
  height: 24px;
}

/* Profile section Container / Right Section */
.top-profile-container {
  display: flex;
  align-items: center;
}

/*   Profile image wrapper */
.profile-img-wrapper {
  width: 51px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 2px solid transparent;
  border-radius: 50%;
  background: linear-gradient(#eaeaf6, #eaeaf6) padding-box,
    linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%) border-box;
}

.profile-img-wrapper img {
  border-radius: 50%;
  height: 43px;
  width: 43px;
}

/* UIW */
.user-initials-wrapper {
  font-weight: 600;
  font-size: 18px;
}
/* Merchant Name wrapper */
.merchant-name-wrapper {
  margin: 0 15px;
}

.merchant-name-wrapper .merchant-user-name {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #1a0404;
}
.merchant-name-wrapper .merchant-title {
  font-size: 12px;
  line-height: 16px;
  color: #8e8e8e;
}

/* settings-icon-wrapper */
.settings-icon-wrapper {
  cursor: pointer;
}
